import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    IonBadge,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuToggle,
    IonRouterLink,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import { logIn } from 'ionicons/icons';
import { PrincipalService } from '../../auth/principal.service';
import { User } from '../../core/model/user.model';
import { Navigation, studentNavMenu, teacherNavMenu } from '../../model/navigation.model';
import { ProfilePanel } from '../profile-panel/profile-panel';

@UntilDestroy()
@Component({
    selector: 'app-menu',
    standalone: true,
    imports: [
        ProfilePanel,
        TranslateModule,
        FontAwesomeModule,
        RouterModule,
        IonRouterLink,
        IonList,
        IonListHeader,
        IonMenuToggle,
        IonItem,
        IonLabel,
        IonBadge,
        IonIcon,
    ],
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnDestroy {
    private _principal = inject(PrincipalService);
    private cdr = inject(ChangeDetectorRef);

    @Input()
    account: User;
    @Input('loggedIn')
    set loggedIn(val: boolean) {
        const user = this._principal.getAuthenticatedUser();
        if (val && user?.ui) {
            const isStudentAccount = user?.ui == 'STUDENT';
            if (isStudentAccount) {
                this.appPages = studentNavMenu;
                if (isStudentAccount) {
                    this.initBadgeCounters();
                }
            } else {
                this.appPages = teacherNavMenu;
            }
        } else {
            this.appPages = [];
        }
        this._loggedIn = val;
    }
    _loggedIn: boolean;

    appPages: Navigation[] = [];
    constructor() {
        addIcons({ logIn });
    }

    initBadgeCounters() {
        this._principal.newMessageCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.appPages.find(p => p.id == 7).count = result;
            this.cdr.detectChanges();
        });
        this._principal.totalAssignmentCount.pipe(untilDestroyed(this)).subscribe(result => {
            console.log('PAGES', this.appPages);
            this.appPages.find(p => p.id == 4).count = result;
            this.cdr.detectChanges();
        });
        this._principal.totalCourseCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.appPages.find(p => p.id == 3).count = result;
            this.cdr.detectChanges();
        });
        this._principal.newChatMessageCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.appPages.find(p => p.id == 6).count = result;
            this.cdr.detectChanges();
        });
        this._principal.newAnnouncementCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.appPages.find(p => p.id == 8).count = result;
            this.cdr.detectChanges();
        });
    }
    ngOnDestroy() {
        console.log('On menu Destroy');
    }
}
